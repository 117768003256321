import axios from "axios";

const url = "https://bilhete-app-api.onrender.com/checkout/";

export async function getCheckoutFromAPI(id) {
  try {
    const response = await axios.get(`${url}${id}`);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}

export async function getCheckoutStatusFromAPI(id) {
  try {
    const response = await axios.get(`${url}status/${id}`);
    return response.data.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}
