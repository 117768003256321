import { Grid } from "@mui/material";
import Slide from "@mui/material/Slide";
import * as React from "react";

import logoBilhete from "../../assets/imgs/logo.png";
import { Menu } from "@mui/icons-material";

export default function HeaderMenu() {
  return (
    <Grid item xs="12" className="headerRifa">
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={8} md={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{ pl: 2, pr: 2 }}
          >
            <Grid item xs="6">
              <a className="navbar-brand">
                <img src={logoBilhete} alt="Logo" height={40} />
              </a>
            </Grid>
            <Grid item xs="6">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                height={40}
                alignItems="center"
              >
                <Grid item>
                  <Menu />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
