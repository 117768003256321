import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ProvacyPolicy from "./ProvacyPolicy";
import reportWebVitals from "./reportWebVitals";
import toast, { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Rifa from "./Rifa";
import Checkout from "./Pages/Checkout";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/rifa/:username/:uuid",
    element: <Rifa />,
  },
  {
    path: "/checkout/:uuid",
    element: <Checkout />,
  },
  {
    path: "/privacidade",
    element: <ProvacyPolicy />,
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f67f3",
    },
    secondary: {
      main: "#3f67f3",
      light: "#F5EBFF",
      contrastText: "#FFFFFF",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <Toaster gutter={8} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
